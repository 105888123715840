// const appName = '小程序'

const routers = {
  mode: 'history',
  routes: [
    {
      name: 'index',
      path: '/',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/index.vue'),
      meta: {
        title: 'Decathlon'
      }
    },
  ]
}

export default routers
