<template lang="pug">
.app.h100p
  router-view
</template>

<script>
export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1',
      },
    ],
  },
  created () {
    // ios页面输入框失焦要页面重置到顶部
    if (this.$u.isIos()) {
      // 判断是否是ios端
      let flag = false
      let pageBackNormFunc
      document.body.addEventListener('focusin', () => {
        // 软键盘弹起事件
        flag = true
        pageBackNormFunc && clearTimeout(pageBackNormFunc)
      })
      document.body.addEventListener('focusout', () => {
        // 软键盘关闭事件
        if (flag) {
          pageBackNormFunc = setTimeout(function () {
            // // 当键盘收起的时候让页面回到原始位置
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }, 200)
        }
        flag = false
      })
    }
  },
}
</script>

<style lang="stylus">
.app
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
</style>
