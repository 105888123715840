import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from './routers.js'
// import store from '@/store'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const router = new VueRouter(routers)

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // const profile = store.getters.profile
  // console.log('profile', profile)
  // if (to.name === 'login' && profile) {
  //  next({ name: 'home' })
  // } else if (to.name !== 'login' && to.name !== 'f' && !profile) {
  //  console.log(to.name)

  //  next({ name: 'login' })
  // } else {
  //  next()
  // }
  // if (!profile && (to.name !== 'login' || to.name !== 'k')) {
  //  next({ name: 'login' })
  // }
  next()
})

// 全局后置守卫
router.afterEach((to, from) => {
  document.title = to.meta.title
})

export default router
