// import moment from 'moment'
// import Vue from 'vue'
// import moment from 'moment-timezone'
const isIos = () => {
  const u = navigator.userAgent
  return !!u.match(/\(i[^]+( U)? CPU.+Mac OS X/)
}

export default {
  isIos,
}
