import Vue from 'vue'
import App from './App.vue'
import router from './router'
import utils from './utils'

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/hint/show-hint.css'

import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './element-variables.scss'

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import './styles/main.scss'
import Meta from 'vue-meta'
import EleUploadImage from 'vue-ele-upload-image'
import VueCookies from 'vue-cookies'
// import storage from '@/storage'
import '@/assets/css/index.css'
import VueClipboard from 'vue-clipboard2'
import Vant, { Icon } from 'vant'

import 'vant/lib/index.css'
import 'vant/lib/icon/local.css'

Vue.use(VueCookies)
Vue.use(Meta)
Vue.use(Vant)
Vue.use(Icon)
Vue.use(codemirror)
Vue.use(ElementUI)
Vue.use(VXETable)
Vue.use(VueClipboard)
Vue.component(EleUploadImage.name, EleUploadImage)

Vue.config.productionTip = false

Vue.prototype.$u = utils

// main
new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount('')
